import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { TranslationPipe } from '../../../../features/translate/translation.pipe';
import { logErrorInSentry } from '../../../errors/response-error';

export type TEditingQuill = {
  editing: boolean;
  fieldId: string;
};

@Injectable({
  providedIn: 'root',
})
export class PointFieldsService {
  private isEditingQuill: TEditingQuill = {
    editing: false,
    fieldId: null,
  };

  constructor(private promptService: PromptService, private translationPipe: TranslationPipe) {}

  createCustomField2(
    fieldId: string,
    fieldValue: string | null,
    fieldType?: ECustomFieldType,
  ): { [x: string]: string }[] {
    if (
      (fieldType === ECustomFieldType.COST ||
        fieldType === ECustomFieldType.PERCENTAGE ||
        fieldType === ECustomFieldType.NUMBERS) &&
      fieldValue
    ) {
      fieldValue = fieldValue.replace(/,/g, '').replace(/%/g, '').trim();
    } else if (!fieldValue) {
      fieldValue = null;
    }

    return [
      {
        [fieldId]: fieldValue ? fieldValue.toString() : null,
      },
    ];
  }

  createCustomFieldRichText(fieldId: string, fieldValue: string): { [x: string]: string }[] {
    return [
      {
        [fieldId]: fieldValue ? fieldValue.toString() : fieldValue,
      },
    ];
  }

  createCustomFieldCheckbox(fieldId: string, fieldValue: boolean): { [x: string]: string }[] {
    return [
      {
        [fieldId]: fieldValue ? fieldValue.toString() : 'false',
      },
    ];
  }

  getIsEditingQuill(): TEditingQuill {
    return this.isEditingQuill;
  }

  setIsEditingQuill(editing: boolean, fieldId?: string): void {
    if (this.isEditingQuill.fieldId !== fieldId && !editing) {
      return;
    }

    this.isEditingQuill.editing = editing;
    this.isEditingQuill.fieldId = editing ? fieldId : null;
  }

  showUpdatePointFieldError(error: HttpErrorResponse): void {
    let promptText = this.translationPipe.transform('prompt_changes_error');

    if (error.status === EStatusCode.NOT_FOUND) {
      promptText = this.translationPipe.transform('prompt_changes_error_field_deleted');
    } else if (error.status === EStatusCode.FORBIDDEN) {
      promptText = this.translationPipe.transform('prompt_changes_permission_denied');
    } else {
      logErrorInSentry(error);
    }

    this.promptService.showError(promptText);
  }
}
