import { createElement } from 'src/app/core/helpers/dom';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { isPointFlagged } from 'src/app/project/modules/points/utils/is-flagged';
import { GET_TIMELINE } from '../../timeline.ui.store';
import { createTimelineCellElement } from '../body-cells/create-timeline-cell-element';
import { createTimelineFlagElement } from '../body-cells/create-timeline-flag-element';
import { createTimelinePriorityElement } from '../body-cells/create-timeline-priority-element';
import { createTimelineResizeElement } from '../resize-element';

export function createTimelineLeftElement(point: TPoint): HTMLElement {
  const name = `${point.sequenceNumber} - ${point.title}`;
  const flagged = isPointFlagged(point);
  const priorityElement = createTimelinePriorityElement(point.status, point.priority);

  let childrenList: (HTMLElement | string)[] = createChildren(name, point);

  const nameElement: HTMLElement = createElement('div', {
    attrs: {
      class: 'timeline__groupedTitleCell--inner',
    },
    children: childrenList,
  });

  const resizeElement = createTimelineResizeElement();

  addTooltip(point, nameElement);

  const classList = getElementClassList(flagged);

  return createGroupedCellElement(classList, priorityElement, point, nameElement, resizeElement);
}

function createGroupedCellElement(
  classList: string[],
  priorityElement: HTMLElement,
  point: TPoint,
  nameElement: HTMLElement,
  resizeElement: HTMLElement,
): HTMLElement {
  const timeline = GET_TIMELINE();

  const groupedCell = createTimelineCellElement({
    className: classList.join(' '),
    center: true,
    child: priorityElement,
    callback: () => {
      if (!timeline.resizing) {
        timeline.openPointCallback(point);
      }
    },
  });

  groupedCell.appendChild(nameElement);
  groupedCell.appendChild(resizeElement);

  return groupedCell;
}

function addTooltip(point: TPoint, nameElement: HTMLElement): void {
  const nameTooltip = new Tooltip({
    title: point.title,
    mobileTooltip: false,
    childTooltip: false,
    element: nameElement,
    trimTooltip: true,
    trimElement: nameElement,
  });

  nameTooltip.createTooltip();
}

function createChildren(name: string, point: TPoint): (string | HTMLElement)[] {
  let childrenList: (HTMLElement | string)[] = [name];

  if (isPointFlagged(point)) {
    childrenList.unshift(createTimelineFlagElement(point));
  }

  return childrenList;
}

function getElementClassList(flagged: boolean) {
  const classList = ['timeline__groupedTitleCell timelineColumnWidth'];

  if (flagged) {
    classList.push('timeline__groupedTitleCell--flagged');
  }
  return classList;
}
