import { Component, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';

import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ModalService } from '../../../../components/modal/modal.service';

import { ShareModalComponent } from '../../../share/share-modal/share-modal.component';

import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ScreenService } from '@core/services';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { SidePanelService } from 'src/app/project/modules/layout/side-panel/side-panel.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { ImportUsersModalComponent } from '../../../share/import-users-modal/import-users-modal.component';
import { TShareModalComponentData } from '../../../share/share-modal/share-modal-component.model';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { SharesService } from '../../../share/shares.service';
import { TUser } from '../../../user/user.model';
import { UserService } from '../../../user/user.service';
import { TWorkspacesById } from '../../../workspace/workspace.model';
import { SiteSettingsUsersService } from './site-settings-users.service';

@Component({
  selector: 'pp-site-settings-users',
  templateUrl: './site-settings-users.component.html',
  styleUrls: ['./site-settings-users.component.scss'],
})
export class SiteSettingsUsersComponent implements OnInit {
  searchBox = '';
  workspacesLoaded = true;

  offline$: Observable<boolean>;
  offline: boolean;

  workspaces: TWorkspacesById;
  processingExcel: boolean;
  workspaceId: string;

  EUserRole = EUserRole;
  user: TUser;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{ offline: boolean }>,
    private modalService: ModalService,
    private sidePanelService: SidePanelService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private titleService: Title,
    private sharesService: SharesService,
    private screenService: ScreenService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private siteSettingsUsersService: SiteSettingsUsersService,
  ) {
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
  }

  ngOnInit() {
    this.titleService.setTitle('Site settings | Pinpoint Works');
    this.workspacesLoaded = false;

    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe((isDesktop) => {
      if (!isDesktop) {
        this.sidePanelService.collapsePanel();
      }
    });

    this.workspaceService
      .generateWorkspaces()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.workspacesLoaded = true;

          this.workspaces = this.workspaceService.getWorkspaces();
          this.user = this.userService.getUser();
        }),
      )
      .subscribe();

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.activatedRoute.parent.paramMap.subscribe((params) => {
      this.workspaceId = params['params'].id;
    });
  }

  newShare(): void {
    const workspaceId = this.activeService.getActiveWorkspaceId();

    this.modalService.setData<TShareModalComponentData>({
      workspaceId,
    });

    this.modalService.showModal(ShareModalComponent, {
      closeWarning: true,
      onClose: () => {
        this.siteSettingsUsersService.newShareCloseTriggerEvent();
      },
    });
  }

  importUsers(): void {
    const workspaceId = this.activeService.getActiveWorkspaceId();

    this.modalService.setData(workspaceId);

    this.modalService.showModal(ImportUsersModalComponent, {
      closeWarning: true,
      onClose: () => {
        this.siteSettingsUsersService.newShareCloseTriggerEvent();
      },
    });
  }

  updateSearchBox(keyword: string): void {
    this.searchBox = keyword;
  }

  uploadExcel(files: File[]): void {
    this.processingExcel = true;
    const workspaceId = this.activeService.getActiveWorkspaceId();

    this.sharesService
      .importUsersFromExcel(this.workspaces[workspaceId], files[0])
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const prompt = this.translationPipe.transform('prompt_share_import');

          this.promptService.showSuccess(prompt);
        }),
        catchError((err) => {
          const prompt = this.translationPipe.transform('prompt_share_import_error');

          this.promptService.showError(prompt);
          return throwError(err);
        }),
        finalize(() => {
          this.processingExcel = false;
        }),
      )
      .subscribe();
  }
}
