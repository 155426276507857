import { Component } from '@angular/core';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-account-user-modal-edit-warning',
  templateUrl: './account-user-modal-edit-warning.component.html',
  styleUrls: ['./account-user-modal-edit-warning.component.scss'],
})
export class AccountUserModalEditWarningComponent {
  EIconPath = EIconPath;
}
