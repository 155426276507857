import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UpdatePreferences } from '../../preferences/preferences.actions';
import { SetAllFilters, SetSiteFilter } from '../site-filter.actions';

import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TPreferences } from '../../preferences/preferences.model';
import { TAllFilters, TFilters, TSelectableTags } from '../site-filter.model';

import { SortingService } from '@core/helpers';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { ActiveService } from '../../../services/active/active.service';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { WorkspaceService } from '../../workspace/workspace.service';

import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { EStore } from '../../../shared/enums/store.enum';
import { AccountService } from '../../account/account-service/account.service';
import { AdvancedFilterService } from '../../filters-advanced/advanced-filter.service';
import { FilterAssetService } from '../../preferences/filter-asset.service';
import { GET_USER } from '../../user/user.store';
import { EUserType } from '../../users/user-types-enum';
import { checkCustomWorkspaceId } from '../../workspace/workspace';
import { CorrectFilterService } from '../correct-filter.service';
import { DefaultFilterService } from '../default-filter.service';
import { SET_FILTERS } from '../filter-store';
import { checkNumberOfAppliedFilters } from './check-number-of-applied-filters';
import { createCustomFieldFilters } from './create-custom-field-filter';

@Injectable({
  providedIn: 'root',
})
export class SiteFilterDataService implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private filters: TFilters;
  private readonly selectableTags: TSelectableTags;
  private readonly selectableExcludedTags: TSelectableTags;
  private readonly allTags: TSelectableTags;

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string = this.activeService.getActiveWorkspaceId();
  private allFilters: TAllFilters;
  private savedFilter: TAllFilters;

  constructor(
    private store: Store<{
      preferences: TPreferences;
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private activeService: ActiveService,
    private preferencesService: PreferencesService,
    private customFieldsService: CustomFieldsService,
    private workspaceService: WorkspaceService,
    private router: Router,
    private sortingService: SortingService,
    private defaultFilterService: DefaultFilterService,
    private correctFilterService: CorrectFilterService,
    private accountService: AccountService,
    private advancedFilterService: AdvancedFilterService,
    private filterAssetService: FilterAssetService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.selectableTags = { data: [] };
    this.selectableExcludedTags = { data: [] };
    this.savedFilter = {};

    this.allTags = { data: [] };

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.filtersHandler(allFilters);
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspacesHandler(workspaces);
    });
  }

  ngOnInit(): void {
    this.store.dispatch(
      new SetAllFilters({
        filters: {},
      }),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getFilters(): TFilters {
    return this.filters;
  }

  getSelectableTags(): TSelectableTags {
    return this.selectableTags;
  }

  getSelectableExcludedTags(): TSelectableTags {
    return this.selectableExcludedTags;
  }

  getAllTags(): TSelectableTags {
    return this.allTags;
  }

  setSelectableTags(selectableTags: string[]): void {
    this.selectableTags.data = selectableTags.sort((a, b) => this.sortingService.naturalSort(a, b));
  }

  setSelectableExcludedTags(selectableTags: string[]): void {
    this.selectableExcludedTags.data = selectableTags.sort((a, b) =>
      this.sortingService.naturalSort(a, b),
    );
  }

  setAllTags(tags: string[]): void {
    this.allTags.data = tags.sort((a, b) => this.sortingService.naturalSort(a, b));
  }

  saveFilter(): void {
    const workspaceId = this.activeService.getActiveWorkspaceId();
    const preferences = this.preferencesService.getPreferences();

    let savedFilter = this.allFilters;

    if (!savedFilter) {
      // this.saveFilterToBackend({});
      savedFilter = {};
    }

    if (workspaceId) {
      savedFilter[workspaceId] = this.filters;

      this.saveFilterToBackend(savedFilter, workspaceId);

      this.store.dispatch(
        new UpdatePreferences({
          workspaces: preferences.workspaces,
          dashlets: preferences.dashlets,
        }),
      );
    } else {
      this.saveCustomWorkspaceFilter(savedFilter, preferences);
    }

    this.store.dispatch(
      new SetAllFilters({
        filters: savedFilter,
      }),
    );
  }

  tryDeleteCustomFieldFromFilters(fieldId: string, workspaceId: string): void {
    this.tryDeleteBasedFilterCustomField(fieldId, workspaceId);
    this.advancedFilterService.tryDeleteAdvancedFilterCustomField(fieldId, workspaceId);
  }

  tryDeleteBasedFilterCustomField(fieldId: string, workspaceId: string): void {
    const savedFilter = this.allFilters;

    if (savedFilter && savedFilter[workspaceId]?.customFields) {
      const index = savedFilter[workspaceId].customFields.findIndex(
        (field) => field.id === fieldId,
      );

      if (index > -1) {
        savedFilter[workspaceId].customFields.splice(index, 1);
        this.saveFilterToBackend(savedFilter, workspaceId);
      }
    }

    this.store.dispatch(
      new SetAllFilters({
        filters: savedFilter,
      }),
    );
  }

  createCustomFieldFilters(): void {
    const workspace = this.workspaceService.getActiveWorkspace();
    const customFields = this.customFieldsService.getCustomFields();
    let workspaceId = this.workspaceId;

    createCustomFieldFilters({
      filters: this.filters,
      savedFilter: this.savedFilter,
      workspace,
      customFields,
    });

    if (!this.workspaceId) {
      workspaceId = checkCustomWorkspaceId();
    }

    if (this.filterAssetService.getOpeningSiteFromAsset()) {
      this.filterAssetService.createAssetFilter(workspaceId);
    } else {
      this.store.dispatch(
        new SetSiteFilter({
          filter: { ...this.filters },
          workspaceId: workspaceId,
        }),
      );
    }
  }

  checkNumberOfAppliedFilters(): void {
    this.filters.numberOfFilters = checkNumberOfAppliedFilters(this.filters);
  }

  saveFilterToBackend(filter: TAllFilters, workspaceId: string): void {
    this.savedFilter = filter;

    const workspace = this.workspaceService.findWorkspace(workspaceId);

    if (workspace) {
      const accountId = workspace.accountId;
      const account = this.accountService.getAccount(accountId);
      const savedViewsEnabled = account?.accountFeatures?.savedViews;

      if (savedViewsEnabled) {
        this.saveFilterToSavedView(filter, workspaceId);
      }
    }

    this.saveFilterToPreferences(filter, workspaceId);

    if (GET_USER().userType === EUserType.DEVELOPER) {
      console.log('TODO Save filter to backend');
    }
  }

  saveFilterToPreferences(filter: TAllFilters, workspaceId: string): void {
    // TODO Save filter to preferences
  }

  saveFilterToSavedView(filter: TAllFilters, workspaceId: string): void {
    if (
      workspaceId === EWorkspaces.OVERVIEW ||
      workspaceId === EWorkspaces.REMINDERS ||
      workspaceId === EWorkspaces.TIMELINE
    ) {
      return this.saveFilterToPreferences(filter, workspaceId);
    }

    // TODO Save filter to saved view
  }

  private workspacesHandler(workspaces: TWorkspacesById): void {
    this.workspaceId = this.activeService.getActiveWorkspaceId();

    if (!this.workspaceId) {
      this.workspaceId = checkCustomWorkspaceId();
    }

    this.correctFilterService.correctFilter({
      workspaces: workspaces,
      currentWorkspaceId: this.workspaceId,
      allFilters: this.allFilters,
      savedFilter: this.savedFilter,
      filters: this.filters,
    });

    this.filters = this.allFilters?.[this.workspaceId];

    if (!this.filters && this.workspaceId) {
      this.defaultFilterService.setDefaultFilter(this.allFilters, this.workspaceId);
    }

    if (this.filters && this.workspaceId) {
      this.checkNumberOfAppliedFilters();
    }
  }

  private filtersHandler(allFilters: TAllFilters): void {
    this.allFilters = cloneDeep(allFilters);

    this.filters = this.allFilters?.[this.workspaceId];

    if (!this.filters && this.workspaceId) {
      this.defaultFilterService.setDefaultFilter(this.allFilters, this.workspaceId);
    }

    if (this.filters?.sites) {
      this.filters.sites = this.filters.sites.sort((a, b) =>
        this.sortingService.naturalSort(a.accountName, b.accountName),
      );
    }

    if (this.filters && this.workspaceId) {
      this.checkNumberOfAppliedFilters();
    }

    SET_FILTERS(this.filters);
  }

  private saveCustomWorkspaceFilter(savedFilter: TAllFilters, preferences: TPreferences): void {
    const isOverview = this.router.url.search(EWorkspaces.OVERVIEW) !== -1;
    const isTimeline = this.router.url.search(EWorkspaces.TIMELINE) !== -1;
    const isReminders = this.router.url.search(EWorkspaces.REMINDERS) !== -1;

    if (isOverview) {
      savedFilter[EWorkspaces.OVERVIEW] = this.filters;
      this.saveFilterToBackend(savedFilter, EWorkspaces.OVERVIEW);

      if (preferences.workspaces && !preferences.workspaces[EWorkspaces.OVERVIEW]) {
        preferences.workspaces[EWorkspaces.OVERVIEW] = {};
      }
    } else if (isTimeline) {
      savedFilter[EWorkspaces.TIMELINE] = this.filters;
      this.saveFilterToBackend(savedFilter, EWorkspaces.TIMELINE);

      if (preferences.workspaces && !preferences.workspaces[EWorkspaces.TIMELINE]) {
        preferences.workspaces[EWorkspaces.TIMELINE] = {};
      }
    } else if (isReminders) {
      savedFilter[EWorkspaces.REMINDERS] = this.filters;
      this.saveFilterToBackend(savedFilter, EWorkspaces.REMINDERS);

      if (preferences.workspaces && !preferences.workspaces[EWorkspaces.REMINDERS]) {
        preferences.workspaces[EWorkspaces.REMINDERS] = {};
      }
    }

    this.store.dispatch(
      new UpdatePreferences({
        workspaces: preferences.workspaces,
        dashlets: preferences.dashlets,
      }),
    );
  }
}
