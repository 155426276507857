<pp-modal
  [ppWidth]="step === EEditAccountUserStep.SHARES_LIST ? 1061 : 626"
  [ppScrollOnDefault]="true"
  [ppNoPadding]="true"
>
  <ng-container heading> {{ 'edit_users' | ppTranslate }} </ng-container>

  <ng-container body *ngIf="!processing">
    <pp-account-user-modal-shares-list
      [ppShowInfoBox]="differentAccessLevelsSelected"
      [ppAccount]="account"
      [ppSelectedWorkspaceIds]="workspaceIds"
      *ngIf="step === EEditAccountUserStep.SHARES_LIST"
      (ppGoBack)="previousStep()"
      (ppShowCustomFieldsTable)="showCustomFieldsTable($event)"
      [ppEmailsReadOnly]="true"
    ></pp-account-user-modal-shares-list>

    <pp-account-user-modal-custom-fields
      [ppWorkspaceId]="customFieldsWorkspaceId"
      *ngIf="step === EEditAccountUserStep.CUSTOM_FIELDS_EDIT"
    ></pp-account-user-modal-custom-fields>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="confirmText"
      [ppProcess]="processing || savingShare"
      (ppCancel)="previousStep()"
      (ppConfirm)="nextStep()"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
