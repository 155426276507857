import { createElement } from 'src/app/core/helpers/dom';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { sanitizeHTML } from 'src/app/core/helpers/text-sanitizer';
import { MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT } from './timeline-variables';

export function createTimelineInnerElement(
  title: string,
  width: number,
  tooltip: Tooltip,
): HTMLElement {
  let originalTitle = '';

  const eventListeners = {
    mouseenter: (): void => {
      if (tooltip._element._tippy) {
        originalTitle = tooltip.title;

        tooltip._element._tippy.setContent(sanitizeHTML(title));
      }
    },
    mouseleave: (): void => {
      if (tooltip._element._tippy) {
        tooltip._element._tippy.setContent(sanitizeHTML(originalTitle));
      }
    },
  };

  if (width > MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT) {
    return createElement('div', {
      attrs: {
        class: 'timeline__fieldElement__innerText timeline__fieldElement__innerText--long',
      },
      eventListeners,
      children: [title],
    });
  }

  const titleElement = createElement('div', {
    attrs: {
      class: 'timeline__fieldElement__innerTextLimiter',
    },
    children: [title],
  });

  return createElement('div', {
    attrs: {
      class: 'timeline__fieldElement__innerText timeline__fieldElement__innerText--thin',
      style: {
        left: '8px',
      },
    },
    eventListeners,
    children: [titleElement],
  });
}
