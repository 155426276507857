import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { canEditPoint } from 'src/app/project/modules/share/share-utils/share-permissions';
import { GET_SHARES } from 'src/app/project/modules/share/shares.store';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { GET_USER } from 'src/app/project/modules/user/user.store';
import { GET_TIMELINE_COLUMNS } from '../../timeframes/timeline-timeframes';
import { GET_TIMELINE } from '../../timeline.ui.store';
import { createTimelineBasicElement } from './create-timeline-basic-element';
import { createTimelineEmptyElement } from './create-timeline-empty-element';
import { createTimelineInnerElement } from './create-timeline-inner-element';
import { createTimelinePriorityElement } from './create-timeline-priority-element';
import { createTimelineResizeHandleLeft } from './create-timeline-resize-handle-left';
import { createTimelineResizeHandleRight } from './create-timeline-resize-handle-right';
import { createTimelineCustomFieldTooltip } from './create-timeline-custom-field-tooltip';
import { getTimeAsUtcMidday } from 'src/app/core/helpers/date/date';

export function createTimelineCustomFieldElement(pointIndex: number): HTMLElement {
  const timeline = GET_TIMELINE();
  const data = GET_TIMELINE_COLUMNS();
  const shares = GET_SHARES();

  const index = pointIndex;
  const point = timeline.points[index];
  let timelineFieldId;

  if (point?.customFieldsMap) {
    timelineFieldId = Object.keys(point.customFieldsMap).find(
      (_customFieldId) => point.customFieldsMap[_customFieldId].type === ECustomFieldType.TIMELINE,
    );
  }

  if (timelineFieldId) {
    const timelineField = point.customFieldsMap[timelineFieldId];

    if (timelineField.value) {
      const preferences = GET_PREFERENCES();
      const values = timelineField.value.split('~');

      const startDate = data.timeframes.findIndex(
        (_timeframe) => _timeframe >= parseFloat(values[0]),
      );

      const endDate = data.timeframes.findIndex(
        (_timeframe) => _timeframe >= parseFloat(values[1]),
      );

      const startDifference = Math.floor(
        (values[0] - data.timeframes[startDate - 1]) /
          (data.timeframes[startDate] - data.timeframes[startDate - 1]),
      );

      const numberOfColumnsToAdd = endDate - startDate + 1;

      const width = data.bottomWidth * numberOfColumnsToAdd;
      const title = point.title;
      const id = point.sequenceNumber;
      const priority = point.priority;
      const status = point.status;
      const workspaceId = point.workspaceRef.id;

      const share = shares.find((_share) => _share.workspaceId === workspaceId);
      const canEdit = canEditPoint(share.shareOption, GET_USER());

      const date =
        transformDate(getTimeAsUtcMidday(new Date(+values[0])), '', preferences.dateFormat) +
        ' - ' +
        transformDate(getTimeAsUtcMidday(new Date(+values[1])), '', preferences.dateFormat);
      const timelineGroup = preferences.timeline?.group ? preferences.timeline.group : null;

      const element = createTimelineBasicElement({
        width,
        data,
        pointIndex,
        startDate,
        startDifference,
        timelineGroup,
        timelineField,
        canEdit,
      });

      const tooltip = createTimelineCustomFieldTooltip(date, element, timeline);
      const fieldTitle = `${id} - ${title}`;

      element.appendChild(
        createTimelineResizeHandleLeft(pointIndex, width, timelineField, canEdit, element),
      );
      element.appendChild(createTimelinePriorityElement(status, priority));
      element.appendChild(createTimelineInnerElement(fieldTitle, width, tooltip));
      element.appendChild(
        createTimelineResizeHandleRight(pointIndex, width, timelineField, canEdit, element),
      );

      return element;
    } else {
      return createTimelineEmptyElement(pointIndex);
    }
  } else {
    return createTimelineEmptyElement(pointIndex);
  }
}
