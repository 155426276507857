import { TAccount } from './account.model';

let account: TAccount = null;
let accounts: TAccount[] = [];

export type TAccountExtras = {
  label: string;
  value: string;
};

const industries: TAccountExtras[] = [
  { label: 'education', value: 'EDUCATION' },
  { label: 'marine_commercial', value: 'MARINE_COMMERCIAL' },
  { label: 'marine_cruise', value: 'MARINE_CRUISE' },
  { label: 'marine_small_production', value: 'MARINE_SMALL_PRODUCTION' },
  { label: 'marine_super_yacht', value: 'MARINE_SUPER_YACHT' },
  { label: 'marine_other', value: 'MARINE_OTHER' },
  { label: 'property_commercial', value: 'PROPERTY_COMMERCIAL' },
  { label: 'property_hotel', value: 'PROPERTY_HOTEL' },
  { label: 'property_residential', value: 'PROPERTY_RESIDENTIAL' },
  { label: 'property_other', value: 'PROPERTY_OTHER' },
  { label: 'other', value: 'OTHER' },
];

const accountManagers: TAccountExtras[] = [
  { label: 'Leslie', value: 'LESLIE' },
  { label: 'Stuart', value: 'STUART' },
  { label: 'Andy', value: 'ANDY' },
  { label: 'Glen', value: 'GLEN' },
  { label: 'Anja', value: 'ANJA' },
  { label: 'Thomas', value: 'THOMAS' },
  { label: 'Victoria', value: 'VICTORIA' },
  { label: 'Syra', value: 'SYRA' },
  { label: 'Gary', value: 'GARY' },
  { label: 'None', value: 'NONE' },
  { label: 'Unset', value: 'UNSET' },
];

// Get

export const GET_ACCOUNT = (): TAccount => account;

// GETTERS

export const GET_ACCOUNTS = (): TAccount[] => accounts;

export const GET_INDUSTRIES = (): TAccountExtras[] => industries;

export const GET_ACCOUNT_MANAGERS = (): TAccountExtras[] => accountManagers;

// Set

export const SET_ACCOUNT = (_account: TAccount): void => {
  account = _account;
};

// SETTERS

export const SET_ACCOUNTS = (_accounts: TAccount[]): void => {
  accounts = _accounts;
};

// Modify

// ---

// Clear

// ---
