<div class="tableWrapper">
  <table aria-label="Site users table" class="settingsTable">
    <thead class="settingsTable__head">
      <tr class="settingsTable__row settingsTable__row--header">
        <th>
          <pp-checkbox
            [ppChecked]="isAllSelected"
            ppSize="medium"
            class="checkbox"
            ppType="solid"
            (ppOnValueChange)="selectAllShares()"
          ></pp-checkbox>
        </th>
        <th scope="col" class="settingsTable__header settingsTable__username-row">
          {{ 'username_and_email' | ppTranslate }}
        </th>

        <th scope="col" class="hide--mobile settingsTable__header settingsTable__loginDate">
          {{ 'last_login_date' | ppTranslate }}
        </th>

        <th scope="col" class="hide--mobile settingsTable__header settingsTable__access">
          {{ 'access' | ppTranslate }}
        </th>

        <th scope="col" class="hide--mobile settingsTable__header settingsTable__tags-header">
          <span class="settingsTable__header--tags"> {{ 'accessible_tags' | ppTranslate }} </span>
        </th>

        <th scope="col" class="settingsTable__content-right settingsTable__header">
          {{ 'actions' | ppTranslate }}
        </th>
      </tr>
    </thead>

    <tbody class="settingsTable__body">
      <ng-container *ngFor="let share of sortedFilteredShares">
        <tr class="settingsTable__row" *ngIf="share.shareOption !== EUserRole.OWNER">
          <td>
            <pp-site-settings-users-select-cell
              [ppShareId]="share.shareId"
              [ppDisabled]="share.shareOption === EUserRole.ACCOUNT_ADMIN"
            ></pp-site-settings-users-select-cell>
          </td>

          <td class="settingsTable__basic-info settingsTable__cell">
            <div class="settingsTable__user">
              <img
                ppTooltip
                ppImage
                class="settingsTable__avatar"
                *ngIf="users[share.userId]?.avatarPublicUrl"
                [src]="users[share.userId].avatarPublicUrl"
                [ppTitle]="users[share.userId].userName"
              />

              <img
                class="settingsTable__avatar"
                [src]="EIconPath.PLACEHOLDER_USER"
                *ngIf="!users[share.userId]?.avatarPublicUrl"
              />

              <div class="settingsTable__multirow">
                <p class="settingsTable__multirow-top">
                  <span
                    [innerHTML]="users[share.userId]?.userName || '' | highlightKeyword: ppKeyword"
                  ></span>
                </p>

                <p class="settingsTable__multirow-bottom">
                  <span
                    [innerHTML]="users[share.userId]?.email | highlightKeyword: ppKeyword"
                  ></span>
                </p>
              </div>
            </div>
          </td>

          <td class="hide--mobile settingsTable__cell settingsTable__loginDate">
            <div class="settingsTable__loginDate--text">
              <ng-container
                *ngIf="
                  users[share.userId]?.lastActivityEpochMillis && users[share.userId]?.verified
                "
              >
                {{ users[share.userId]?.lastActivityEpochMillis | ppDate }}
              </ng-container>

              <ng-container
                *ngIf="
                  !users[share.userId]?.lastActivityEpochMillis && users[share.userId]?.verified
                "
              >
                {{ 'N/A' | ppTranslate }}
              </ng-container>
            </div>
          </td>

          <td class="hide--mobile settingsTable__cell settingsTable__shareOption">
            <div class="settingsTable__shareOption--text">
              <span
                [innerHTML]="shareOptionLabels[share.shareOption] | highlightKeyword: ppKeyword"
              ></span>
            </div>
          </td>

          <td class="hide--mobile settingsTable__cell settingsTable__tags">
            <span *ngIf="share.tagLimited && share.defectTags.length > 0">
              <span class="sw-tag-sm" *ngFor="let tag of share.defectTags">
                <span [innerHTML]="tag | highlightKeyword: ppKeyword"></span>
              </span>
            </span>

            <span
              class="settingsTable__tagInfo"
              *ngIf="
                share.tagLimited &&
                share.defectTags.length === 0 &&
                share.shareOption !== EUserRole.SITE_ADMIN
              "
            >
              <span [innerHTML]="'None' | highlightKeyword: ppKeyword"></span>
            </span>

            <span
              class="settingsTable__tagInfo"
              *ngIf="!share.tagLimited || share.shareOption === EUserRole.SITE_ADMIN"
            >
              <span [innerHTML]="'All' | highlightKeyword: ppKeyword"></span>
            </span>
          </td>

          <td
            class="settingsTable__content-right settingsTable__cell"
            [ngClass]="
              users[share.userId]?.verified ? '' : 'settingsTable__content-right--expanded'
            "
          >
            <div class="settingsTable__content-right--buttons">
              <pp-button
                ppTooltip
                ppTitle="Resend Invitation"
                ppStyle="secondary"
                id="resendInviteButton"
                [class.cursor--disabled]="ppOffline"
                ppId="resendInvitationBtn-{{ share.shareId }}"
                *ngIf="!users[share.userId]?.verified"
                (ppAction)="resendInvitation(share)"
                ppSize="small"
                [ppDisabled]="ppOffline"
              >
                <pp-icon
                  [ppSrc]="EIconPath.ICON_MISC_MAIL_18"
                  ppColor="grey-500"
                  ppClass="setHeight17"
                ></pp-icon>
              </pp-button>

              <pp-edit-button
                (ppAction)="editShare(share)"
                ppTooltip
                [ppTitle]="
                  share.shareOption === EUserRole.ACCOUNT_ADMIN
                    ? ('cant_edit_account_admin_permission' | ppTranslate)
                    : ''
                "
                [ppDisabled]="ppOffline || share.shareOption === EUserRole.ACCOUNT_ADMIN"
                ppSize="small"
                ppId="editUserBtn-{{ share.shareId }}"
                [class.cursor--disabled]="
                  ppOffline || share.shareOption === EUserRole.ACCOUNT_ADMIN
                "
              ></pp-edit-button>

              <pp-delete-share-button
                ppTooltip
                [ppTitle]="
                  share.shareOption === EUserRole.ACCOUNT_ADMIN
                    ? ('cant_edit_account_admin_permission' | ppTranslate)
                    : ''
                "
                [ppShare]="share"
                [ppDisabled]="share.shareOption === EUserRole.ACCOUNT_ADMIN"
              ></pp-delete-share-button>
            </div>
          </td>
        </tr>
      </ng-container>

      <div class="settingsSection__no-content" *ngIf="workspaceShares.data.length <= 1">
        {{ 'no_users_found' | ppTranslate }}
      </div>

      <div *ngIf="!workspaceShares && ppOffline">
        <h5 class="settingsSection__notAvailable">
          {{ 'data_unavailable_offline' | ppTranslate }}
        </h5>
      </div>
    </tbody>
  </table>
</div>
