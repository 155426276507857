import { createElement } from '@core/helpers';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { translate } from 'src/app/project/features/translate/translate';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { isPointFlagged } from 'src/app/project/modules/points/utils/is-flagged';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

export function createTimelineFlagElement(point: TPoint): HTMLElement {
  const flagged = isPointFlagged(point);

  const element = createChildElement(flagged);

  addFlagTooltip(element, flagged);

  return element;
}

function createChildElement(flagged: boolean): HTMLImageElement {
  return createElement('img', {
    attrs: {
      class: 'table__flag',
      src: flagged
        ? EIconPath.ICON_ACTIVITIES_RED_FLAG_ACTIVATED_14
        : EIconPath.ICON_ACTIVITIES_RED_FLAG_14,
    },
  });
}

function addFlagTooltip(element: HTMLElement, flagged: boolean): void {
  const tooltipMessage = flagged ? 'flagged' : 'unflagged';

  const flagTooltip = new Tooltip({
    title: translate(tooltipMessage),
    mobileTooltip: false,
    childTooltip: false,
    element: element,
    trimTooltip: false,
    trimElement: element,
  });

  flagTooltip.createTooltip();
}
