<div class="assetCard__sitesHeaderContainer">
  <div
    [ppTrimTooltip]="true"
    class="assetCard__siteNameColumn assetCard__noOverflow"
    ppTooltip
    [ppTitle]="ppAssetSite.siteName"
  >
    <a
      (click)="navigateToSite()"
      [innerHtml]="ppAssetSite.siteName | highlightKeyword: keyword"
    ></a>
  </div>
  <div class="assetCard__progressColumn assetCard__siteColumnData">{{ ppAssetSite.progress }}%</div>
  <div class="assetCard__pointsColumn assetCard__siteColumnData">{{ ppAssetSite.pointCount }}</div>
  <div
    class="assetCard__budgetColumn assetCard__siteColumnData"
    ppTooltip
    [ppTitle]="customFieldName"
    [ngClass]="ppAssetSite.budget >= 0 ? '' : 'emptyCell'"
  >
    {{ budget }}
  </div>
  <div
    class="assetCard__redFlagsColumn assetCard__siteColumnData assetCard__redFlagsColumn--clickable"
    (click)="openSiteWithRedFlags()"
  >
    <div
      class="assetCard__flagBadge"
      [ngClass]="ppAssetSite.redFlagCount > 0 ? 'redBadge' : 'greenBadge'"
    >
      {{ ppAssetSite.redFlagCount }}
    </div>
  </div>
</div>
