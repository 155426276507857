<input
  #daysInput
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="inputDays"
  (ngModelChange)="onModelChange()"
  *ngIf="ppCanEdit && !ppShowHoursOnly"
  placeholder="d"
  inputmode="numeric"
  pattern="[0-9]*"
  (keydown.enter)="updateField()"
  [style.width]="ppWidth"
/>

<input
  #hoursInput
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="inputHours"
  (ngModelChange)="onModelChange()"
  *ngIf="ppCanEdit"
  placeholder="h"
  inputmode="numeric"
  pattern="[0-9]*"
  (keydown.enter)="updateField()"
  [style.width]="ppWidth"
/>

<input
  #minutesInput
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="inputMinutes"
  (ngModelChange)="onModelChange()"
  *ngIf="ppCanEdit"
  placeholder="min"
  inputmode="numeric"
  pattern="[0-9]*"
  (keydown.enter)="updateField()"
  [style.width]="ppWidth"
/>
