import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { SortingService } from '@core/helpers';
import { Store } from '@ngrx/store';
import { Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { RemindersService } from 'src/app/project/modules/reminders/reminders.service';
import { SiteOverviewService } from 'src/app/project/modules/site/site-overview/site-overview.service';
import { SiteTimelineService } from 'src/app/project/modules/site/site-timeline/site-timeline.service';
import { checkCustomWorkspaceId } from 'src/app/project/modules/workspace/workspace';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { PointsFetchingService } from '../../../points/points-fetching.service';
import { ApplyFiltersService } from '../../apply-filters.service';
import { SiteFilterDataService } from '../../site-filter-data-service/site-filter-data.service';
import { SetSiteFilter } from '../../site-filter.actions';
import { TFilters } from '../../site-filter.model';
import { SitePointFilterService } from '../../site-point-filter.service';
import { removeDuplicates } from '../site-filter-dropdown-utils/remove-duplicates';
import { sortCustomFieldFilters } from '../site-filter-dropdown-utils/sort-custom-field-filters';

@Injectable({
  providedIn: 'root',
})
export class SiteFilterDropdownService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private applyFiltersGATimeoutActivated = false;
  private applyFiltersGATimerMs = 60000;

  constructor(
    private store: Store,
    private siteFilterDataService: SiteFilterDataService,
    private applyFiltersService: ApplyFiltersService,
    private siteOverviewService: SiteOverviewService,
    private siteTimelineService: SiteTimelineService,
    private remindersService: RemindersService,
    private sitePointFilterService: SitePointFilterService,
    private sortingService: SortingService,
    private customFieldsService: CustomFieldsService,
    private ngZone: NgZone,
    private pointsFetchingService: PointsFetchingService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  applyAdvancedFilters(): void {
    this.filterPoints();
  }

  applyFilters(filters: TFilters, workspaceId: string): void {
    const currentWorkspaceId = workspaceId || checkCustomWorkspaceId();

    this.store.dispatch(
      new SetSiteFilter({
        filter: filters,
        workspaceId: currentWorkspaceId,
      }),
    );

    if (workspaceId === EWorkspaces.OVERVIEW) {
      this.pointsFetchingService
        .fetchAllPoints({
          filtering: true,
        })
        .pipe(
          tap(() => {
            this.filterPoints();
          }),
        )
        .subscribe();
    } else {
      this.filterPoints();
    }
  }

  private filterPoints(): void {
    this.applyFiltersService.applyFilters();
    this.siteFilterDataService.saveFilter();
    this.sitePointFilterService.filterPoints();
    this.siteFilterDataService.checkNumberOfAppliedFilters();

    if (!this.applyFiltersGATimeoutActivated) {
      this.applyFiltersGATimeoutActivated = true;

      this.ngZone.runOutsideAngular(() => {
        timer(this.applyFiltersGATimerMs)
          .pipe(
            takeUntil(this.destroy$),
            tap(() => {
              this.applyFiltersGATimeoutActivated = false;
            }),
          )
          .subscribe();
      });
    }
  }

  checkOverviewFilter(workspaceId: string): void {
    if (workspaceId === EWorkspaces.OVERVIEW) {
      this.siteOverviewService.updateOverviewFilter();
    } else if (workspaceId === EWorkspaces.TIMELINE) {
      this.siteTimelineService.updateTimelineFilter();
    } else if (workspaceId === EWorkspaces.REMINDERS) {
      this.remindersService.updateRemindersFilter();
    }
  }

  sortFilters(filters: TFilters, customFields: TAllCustomFields): void {
    filters.customFields = sortCustomFieldFilters(filters, customFields);

    filters.sites.sort((a, b) => this.sortingService.naturalSort(a.accountName, b.accountName));

    filters.sites.forEach((account) => {
      account.workspaces.sort((a, b) => this.sortingService.naturalSort(a.name, b.name));
    });

    filters.customFields.sort((a, b) =>
      this.sortingService.naturalSort(
        customFields[a.workspaceId][a.id] ? customFields[a.workspaceId][a.id].label : '',
        customFields[b.workspaceId][b.id] ? customFields[b.workspaceId][b.id].label : '',
      ),
    );
  }

  getCustomFields(workspace: TWorkspace): TAllCustomFields {
    if (workspace) {
      return this.customFieldsService.getCustomFields();
    } else {
      return this.removeDuplicates();
    }
  }

  removeDuplicates(): TAllCustomFields {
    const customFields = this.customFieldsService.getCustomFields();

    return removeDuplicates(customFields);
  }
}
