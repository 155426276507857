<div id="tabContent" class="tabContent siteSettings">
  <pp-grid [ppOptions]="{ bodyPadding: false, headerPaddingSmall: true }">
    <ng-container title>
      {{ 'users' | ppTranslate }}
      <i
        ppTooltip
        [ppOptions]="{ placement: 'right' }"
        class="fa fa-info-circle settingsSection__header-info"
        aria-hidden="true"
        [ppMobileTooltip]="true"
        [ppSanitize]="false"
        [ppTitle]="
          '<p style=\'text-align: left\'>' +
          ('settings_users_1' | ppTranslate) +
          '<br><br>' +
          ('settings_users_2' | ppTranslate) +
          '</p>'
        "
      ></i>
    </ng-container>

    <ng-container titleBar-right>
      <pp-button
        ppStyle="primary"
        ppSize="small"
        class="uploadExcelUsersButton"
        *ngIf="user?.isSuperUser || workspaces?.[workspaceId]?.share.shareOption === EUserRole.OWNER"
      >
        <label class="settingsShare__upload-button" for="excel">
          {{ 'upload' | ppTranslate }}
        </label>

        <pp-upload
          id="excel--update"
          [ppId]="'excel'"
          (ppUpload)="uploadExcel($event)"
          [ppDisabled]="false"
        >
        </pp-upload>
      </pp-button>

      <div class="searchBox__wrapper">
        <pp-search-box
          [ppPlaceholder]="'search_users' | ppTranslate"
          (ppAction)="updateSearchBox($event)"
        ></pp-search-box>
      </div>

      <pp-button
        ppId="addNewUserBtn"
        data-m-target="Add new user button"
        ppStyle="primary"
        ppSize="large"
        (ppAction)="newShare()"
        [ppDisabled]="offline || !workspacesLoaded"
      >
        <span class="hide--mobile">
          {{ 'add_new_user' | ppTranslate }}
        </span>

        <span class="hide--tablet-up">+</span>
      </pp-button>

      <pp-button
        class="settingsShare__importUsers-button"
        ppId="addNewUserBtn"
        data-m-target="Import users button"
        ppStyle="secondary"
        ppSize="large"
        (ppAction)="importUsers()"
        [ppDisabled]="offline || !workspacesLoaded"
      >
        {{ 'import_users' | ppTranslate }}
      </pp-button>
    </ng-container>

    <ng-container body>
      <pp-site-settings-users-table
        *ngIf="workspacesLoaded"
        [ppKeyword]="searchBox"
        [ppOffline]="offline"
      ></pp-site-settings-users-table>

      <pp-site-settings-users-floating-actions
        [ppWorkspaceId]="workspaceId"
      ></pp-site-settings-users-floating-actions>

      <div class="settingsSection__no-content" *ngIf="!workspacesLoaded">
        <app-spinner></app-spinner>
      </div>
    </ng-container>
  </pp-grid>
</div>
