import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TWorkspace } from '../../../workspace/workspace.model';
import { SiteOptionsMoreFloatingActionsEditDropdownComponent } from './site-options-more-floating-actions-edit-dropdown/site-options-more-floating-actions-edit-dropdown.component';

import { ActiveService } from 'src/app/project/services/active/active.service';
import { ModalService } from '../../../../components/modal/modal.service';
import { OfflineService } from '../../../offline/offline.service';
import { SiteTableEventsService } from '../../site-table/site-table-events.service';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { BulkCustomFieldsModalComponent } from '../../../custom-fields/bulk-custom-fields-modal/bulk-custom-fields-modal.component';
import { PointBulkAssigneesModalComponent } from '../../../points/point-modal/point-assignees/point-bulk-assignees-modal/point-bulk-assignees-modal.component';
import { PointBulkPriorityModalComponent } from '../../../points/point-priority/point-bulk-priority-modal/point-bulk-priority-modal.component';
import { PointBulkStatusModalComponent } from '../../../points/point-status/point-bulk-status-modal/point-bulk-status-modal.component';
import { BulkTagsModalComponent } from '../../../tags/bulk-tags-modal/bulk-tags-modal.component';
import { PointsCopyMoveModalComponent } from '../points-copy-move-modal/points-copy-move-modal.component';
import { PointsDeleteModalComponent } from '../points-delete-modal/points-delete-modal.component';

import { PointExportModalComponent } from 'src/app/project/modules/exports/point-export-modal/point-export-modal.component';
import { TExportModalData } from 'src/app/project/modules/exports/utils/export-modal-data';
import {
  canDeletePoint,
  canEditFlag,
  canEditPoint,
} from 'src/app/project/modules/share/share-utils/share-permissions';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from '../../../../shared/enums/store.enum';
import { PointBulkFlagModalComponent } from '../../../points/point-flag-modal/point-flag-modal.component';
import { isPointFlagged } from '../../../points/utils/is-flagged';
import { CustomTableService } from '../../site-table/custom-table/custom-table.service';
import { ECopyMoveModalType } from '../points-copy-move-modal/copy-move-modal-type..enum';
import { TPointsCopyMoveModalData } from '../points-copy-move-modal/points-copy-move-modal.model';
import { TFloatingActionsOptions } from './floating-actions-options.const';

@Component({
  selector: 'pp-floating-actions',
  templateUrl: './site-options-more-floating-actions.component.html',
  styleUrls: ['./site-options-more-floating-actions.component.scss'],
})
export class SiteOptionsMoreFloatingActionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppNumberOfSelectedPoints = 0;
  @ViewChild('dropup', { static: true }) dropup: ElementRef;

  private dropdown: TDropdown = this.dropdownService.getDropdown();
  EUserRole = EUserRole;

  private readonly destroy$ = new Subject<void>();

  user$: Observable<TUser>;
  user: TUser;
  private workspaces$: Observable<TWorkspacesById>;
  workspace: TWorkspace;
  private canEditTags = false;
  canExport = true;
  private canEditCustomFields = false;
  normalWithPermissions = false;
  public offline = false;
  hovered: string = null;
  canDeletePoint = false;
  canEditPoint = false;
  private workspaceId = this.activeService.getActiveWorkspaceId();
  EIconPath = EIconPath;
  haveDifferentFlags = false;
  canEditFlag: boolean;

  constructor(
    private store: Store<{
      user: TUser;
      workspaces: TWorkspacesById;
    }>,
    private modalService: ModalService,
    private activeService: ActiveService,
    private siteTableEventsService: SiteTableEventsService,
    private offlineService: OfflineService,
    private permissionsService: PermissionsService,
    private dropdownService: DropdownService,
    private pointsService: PointsService,
    private customTableService: CustomTableService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit() {
    this.offline = this.offlineService.getOffline();

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      if (!workspaces || Object.keys(workspaces).length === 0) {
        return;
      }

      this.workspace = workspaces[this.workspaceId];

      // FIXME Can we just check `if (!this.workspace)` here? It would simplify the next code
      if (this.workspace === null) {
        this.canEditFlag = false;
        this.canEditTags = false;
        this.canEditCustomFields = false;
        this.canEditPoint = false;
        this.canDeletePoint = false;

        return;
      }

      if (this.workspace) {
        this.canEditFlag = canEditFlag(this.workspace.share.shareOption, this.user);
        this.canDeletePoint = canDeletePoint(this.workspace.share.shareOption, this.user);
        this.canEditPoint = canEditPoint(this.workspace.share.shareOption, this.user);

        const tagPermissions = this.permissionsService.getTagPermissions(
          this.workspace.workspaceId,
        );
        const customFieldPermissions = this.permissionsService.checkWorkspaceCustomFieldPermissions(
          this.workspace.workspaceId,
        );

        this.normalWithPermissions = true;
        this.canEditTags = tagPermissions.edit;
        this.canExport = this.permissionsService.areExportsEnabled(this.workspaceId);

        if (!this.canEditTags) {
          this.normalWithPermissions = false;
        }

        if (Object.keys(customFieldPermissions).length > 0) {
          Object.keys(customFieldPermissions).forEach((customField) => {
            if (customFieldPermissions[customField].edit) {
              this.canEditCustomFields = true;
            } else {
              this.normalWithPermissions = false;
            }
          });
        } else {
          this.canEditCustomFields = canEditPoint(this.workspace.share.shareOption, this.user);

          if (!this.canEditCustomFields) {
            this.normalWithPermissions = false;
          }
        }
      }
    });
  }

  ngOnChanges() {
    if (!this.workspaceId) {
      const selectedWorkspaces = this.pointsService.getOverviewSelectedWorkspaces();
      let exportsEnabled = false;

      selectedWorkspaces.forEach((workspaceId) => {
        if (this.permissionsService.areExportsEnabled(workspaceId)) {
          exportsEnabled = true;
        }
      });

      this.canExport = exportsEnabled;
    }

    this.checkPointFlags();
  }

  checkPointFlags(): void {
    const points = this.pointsService.getSelectedPoints();
    const initialPointFlag = isPointFlagged(points[0]);
    for (let pointId in points) {
      let pointFlag = isPointFlagged(points[pointId]);
      if (!(pointFlag === initialPointFlag)) {
        this.haveDifferentFlags = true;
        return;
      }
    }
    this.haveDifferentFlags = false;
  }

  ngOnDestroy() {
    this.deselectPoints();
    this.destroy$.next();
  }

  bulkChangeFlag(): void {
    this.modalService.showModal(PointBulkFlagModalComponent);
  }

  bulkChangeStatus(): void {
    this.modalService.showModal(PointBulkStatusModalComponent);
  }

  bulkChangePriority(): void {
    this.modalService.showModal(PointBulkPriorityModalComponent);
  }

  bulkChangeAssignees(): void {
    this.modalService.showModal(PointBulkAssigneesModalComponent);
  }

  bulkChangeCustomFields(): void {
    this.modalService.showModal(BulkCustomFieldsModalComponent);
  }

  bulkAddTags(): void {
    this.modalService.showModal(BulkTagsModalComponent);
  }

  copyPoints(): void {
    this.modalService.setData<TPointsCopyMoveModalData>({
      modalType: ECopyMoveModalType.COPY,
      workspaceId: this.workspaceId,
    });

    this.modalService.showModal(PointsCopyMoveModalComponent, {
      closeWarning: true,
    });
  }

  movePoints(): void {
    this.modalService.setData<TPointsCopyMoveModalData>({
      modalType: ECopyMoveModalType.MOVE,
      workspaceId: this.workspaceId,
    });

    this.modalService.showModal(PointsCopyMoveModalComponent, {
      closeWarning: true,
    });
  }

  deletePoints(): void {
    this.modalService.showModal(PointsDeleteModalComponent);
  }

  closeBulkChanges(): void {
    this.deselectPoints();
  }

  deselectPoints(): void {
    if (this.workspaceId) {
      this.siteTableEventsService.deselectWorkspacePoints(this.workspaceId);
    } else {
      this.siteTableEventsService.deselectAllPoints();
    }

    this.customTableService.getTable().deselectPoints();
  }

  export(): void {
    if (this.canExport) {
      const workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.offline) {
        const modalData: TExportModalData = {
          workspaceId,
          formats: {
            simpleList: true,
            detailedList: true,
            csvOutput: true,
            individualReports: true,
            wordPoints: true,
            sitePlan: true,
          },
        };

        this.modalService.setData(modalData);
      } else {
        const modalData: TExportModalData = {
          workspaceId,
          formats: {
            simpleList: false,
            detailedList: false,
            csvOutput: true,
            individualReports: false,
            wordPoints: false,
            sitePlan: false,
          },
        };

        this.modalService.setData(modalData);
      }

      logEventInGTAG(EGoogleEventSite.SITE__BULK_CHANGES__EXPORT, {
        event_category: EGoogleEventCategory.SITE,
        amount: this.ppNumberOfSelectedPoints,
      });

      this.modalService.showModal(PointExportModalComponent, { blur: true });
    }
  }

  buttonHover(id: string): void {
    if (id !== 'export__button' || this.canExport) {
      this.hovered = id;
    }
  }

  buttonHoverEnd(): void {
    this.hovered = null;
  }

  toggleOptionsDropdown(buttonId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      const editingCustomFields =
        this.workspace &&
        this.workspace.customFields &&
        this.workspace.customFields.length &&
        this.canEditCustomFields;

      this.dropdownService.setData({
        canEditCustomFields: editingCustomFields,
        canEditTags: this.canEditTags,
        canEditFlag: this.canEditFlag,
        haveDifferentFlags: this.haveDifferentFlags,
      });

      this.dropdownService.showDropdown(
        buttonId,
        SiteOptionsMoreFloatingActionsEditDropdownComponent,
        {
          callback: (name) => this.openPopup(name),
          popper: {
            positionFixed: true,
            placement: 'top',
          },
          suppressScrollbar: true,
        },
      );
    }
  }

  openPopup(name: TFloatingActionsOptions): void {
    if (name === 'priority') {
      this.bulkChangePriority();
    } else if (name === 'status') {
      this.bulkChangeStatus();
    } else if (name === 'customFields') {
      this.bulkChangeCustomFields();
    } else if (name === 'tags') {
      this.bulkAddTags();
    } else if (name === 'assignees') {
      this.bulkChangeAssignees();
    } else if (name === 'flag') {
      this.bulkChangeFlag();
    }
  }
}
