import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { TSidePanel } from '../../layout/side-panel/side-panel.model';
import { SidePanelService } from '../../layout/side-panel/side-panel.service';
import { TSync } from '../../offline/sync.model';
import { SyncService } from '../../offline/sync.service';
import { TUISettings } from '../../ui/ui.model';
import { WorkspaceService } from '../../workspace/workspace.service';
import { FleetManagementRoutesService } from '../fleet-management-routes.service';
import { EFleetManagementRoutesSegments } from '../fleet-management.routes';
import { FleetService } from '../fleet-service/fleet.service';
import { FleetManagementLandingPageService } from './fleet-management-landing-page.service';

@Component({
  selector: 'pp-fleet-management-landing-page',
  templateUrl: './fleet-management-landing-page.component.html',
  styleUrls: ['./fleet-management-landing-page.component.scss'],
})
export class FleetManagementLandingPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fleetManagement', { static: true }) fleetManagementView: ElementRef;

  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  ui$: Observable<TUISettings>;
  ui: TUISettings;

  private readonly destroy$ = new Subject<void>();

  fleetLoaded = false;
  fleetExists: boolean;

  constructor(
    private sidePanelService: SidePanelService,
    private store: Store<{ ui: TUISettings; sync: TSync }>,
    private syncService: SyncService,
    private fleetService: FleetService,
    private fleetManagementRoutesService: FleetManagementRoutesService,
    private workspaceService: WorkspaceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fleetManagementLandingPageService: FleetManagementLandingPageService,
  ) {
    this.ui$ = this.store.select('ui');
    this.ui$.subscribe((ui) => {
      this.ui = ui;
    });

    this.syncService.firstLoad().pipe(takeUntil(this.destroy$)).subscribe();

    this.fleetService.fleetChange$.pipe(takeUntil(this.destroy$)).subscribe((fleets) => {
      this.fleetExists = Object.values(fleets).some((fleet) => Object.values(fleet).length > 0);
    });

    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      this.tryToRedirectFromDefaultSite(event);
    });
  }

  ngOnInit(): void {
    this.workspaceService
      .generateWorkspaces()
      .pipe(
        switchMap(() =>
          this.fleetService.fetchFleets().pipe(
            tap((response) => {
              this.fleetLoaded = true;

              if (response.length > 0) {
                const fleetId = this.fleetService.getActiveFleetId();

                this.fleetExists = true;

                if (!fleetId) {
                  this.fleetService.setActiveFleetId(response[0].id);
                }

                this.fleetManagementRoutesService.goToFleet(fleetId || response[0].id);
              } else {
                this.fleetManagementRoutesService.goToNewFleetPage();
              }
            }),
          ),
        ),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.fleetManagementLandingPageService.setFleetManagementElement(
      this.fleetManagementView.nativeElement,
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private tryToRedirectFromDefaultSite(event: NavigationEnd) {
    const isDefaultFleetPage =
      event.urlAfterRedirects === `/${EFleetManagementRoutesSegments.FLEET_MANAGEMENT}`;

    if (!isDefaultFleetPage || !this.fleetLoaded) {
      return;
    }

    if (this.fleetExists) {
      const activeFleetId = this.fleetService.getActiveFleetId();
      this.fleetManagementRoutesService.goToFleet(activeFleetId);
    } else {
      this.fleetManagementRoutesService.goToNewFleetPage();
    }
  }
}
